import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}

export default function Label(props: React.PropsWithChildren<Props>) {
  return <span className={cn('label', props.className)}>{props.children}</span>;
}
