import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import type { FavoriteVehicle } from '../../model/FavoriteVehicles';
import RouteEnum from '../../RouteEnum';
import Tracking from '../../services/Tracking';
import type { ReduxState } from '../../store';
import { loadFavoriteVehicles, loadMoreFavoriteVehicles, removeFavoriteVehicle } from '../../store/favoriteVehicles';
import { flashMessageError, flashMessageSuccess } from '../../store/session';
import Button from '../Button';
import HeartIcon from '../Icons/HeartIcon';
import Link from '../Link';
import Loader from '../Loader';
import MetaTags from '../MetaTags';
import T from '../Translate';
import { CarCardLarge } from '../Vehicle/CarCard';
import CarDetailLink from '../Vehicle/CarDetailLink';

import styles from './favorites.module.scss';

export function FavoriteVehiclesPage() {
  const dispatch = useDispatch();
  const { loading, count } = useSelector((store: ReduxState) => ({
    loading: store.favoriteVehicles.loading,
    count: store.favoriteVehicles.data?.total_items ?? 0,
  }));

  useEffect(() => {
    dispatch(loadFavoriteVehicles());
  }, []);

  if (loading) return <FavoriteVehiclesLoadingPage />;
  if (!count) return <FavoriteVehicleEmptyPage />;
  return <FavoriteVehiclesListPage />;
}

function FavoriteVehiclesListPage() {
  const dispatch: DispatchFunction = useDispatch();
  const { data, loadingMore } = useSelector((store: ReduxState) => ({
    data: store.favoriteVehicles.data,
    loadingMore: store.favoriteVehicles.loadingMore,
  }));

  const handleRemoveFromFavorites = (vehicle: FavoriteVehicle) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    Tracking.track('MY_FAVORITE_REMOVE_CLICKED', vehicle);
    dispatch(removeFavoriteVehicle(vehicle.hash))
      .then(() => {
        dispatch(flashMessageSuccess(`favorites.flash.removed`));
      })
      .catch(err => {
        console.error(err);
        dispatch(flashMessageError(`favorites.flash.error`));
      });
    return true;
  };

  const handleLoadMoreClick = () => {
    dispatch(loadMoreFavoriteVehicles());
  };

  return (
    <FavoriteVehiclesPageLayout className={styles.list}>
      <div className={styles.container}>
        {data.items.map(it => (
          <CarDetailLink className={styles.item} hash={it.hash} key={it.hash}>
            <CarCardLarge
              averageRating={it.rating_average_float}
              discount={0}
              favorite
              image={it.main_image}
              kmIncluded={it.mileage}
              name={it.name}
              noOfDays={1}
              onFavoriteClick={handleRemoveFromFavorites(it)}
              pricePerDay={it.price_per_day}
              pricePerKm={it.extra_km_price}
              pricePerOneDay={it.price_per_day}
              ratingsCount={it.ratings_count}
              tags={it.tags}
              totalPrice={it.price_per_day}
            />
          </CarDetailLink>
        ))}

        {data.has_next ? (
          <Button onClick={handleLoadMoreClick} variant="mono">
            <T id="favorites.list.loadMore" />
            {loadingMore ? <Loader white /> : null}
          </Button>
        ) : null}
      </div>
    </FavoriteVehiclesPageLayout>
  );
}

function FavoriteVehicleEmptyPage() {
  return (
    <FavoriteVehiclesPageLayout className={styles.empty}>
      <div className={styles.container}>
        <HeartIcon className={styles.icon} />
        <T as="h2" id="favorites.empty.title" />
        <T as="p" id="favorites.empty.description" />
        <Link
          className="button button-icon button-primary"
          onClick={() => Tracking.track('MY_FAVORITE_BROWSE_CARS_CLICKED')}
          to={RouteEnum.RENT_CAR}
        >
          <T id="favorites.empty.browseCars" />
          <i className="icon icon-search" />
        </Link>
      </div>
    </FavoriteVehiclesPageLayout>
  );
}

function FavoriteVehiclesLoadingPage() {
  return (
    <FavoriteVehiclesPageLayout>
      <Loader />
    </FavoriteVehiclesPageLayout>
  );
}

function FavoriteVehiclesPageLayout({ children, className }: React.PropsWithChildren<{ className?: string }>) {
  return (
    <main className={cn('main-container', className)}>
      <MetaTags title="favorites.meta.title" />
      <T as="h1" className={styles.title} id="favorites.title" />
      {children}
    </main>
  );
}
