import React from 'react';

import { FavoriteVehiclesPage } from '../../views/Favorites/FavoriteVehiclesPage';
import PageLayout from '../../views/Layout/Page';

export default function MyFavoritesPage() {
  return (
    <PageLayout pageview="my_favorites" private>
      <FavoriteVehiclesPage />
    </PageLayout>
  );
}
