import React from 'react';
import cn from 'classnames';

import { formatNumber } from '../../helpers';
import { useLanguage } from '../../hooks/useLanguage';
import type { Document, DocumentType } from '../../model/Document';
import { formatPrice, type PriceType } from '../../model/Price';
import type { VehicleTag } from '../../model/VehicleTag';
import HeartIcon from '../Icons/HeartIcon';
import Image from '../Image';
import Label from '../Label';
import NumberFormat from '../NumberFormat';
import Price from '../Price';
import T, { TContext } from '../Translate';

import styles from './carCard.module.scss';

interface Props {
  name: string;
  image: Document<DocumentType.MAIN_PHOTO>;
  noOfDays: number;
  distance?: number;
  className?: string;
  pricePerDay: PriceType;
  pricePerOneDay: PriceType;
  showPricePerOneDay?: boolean;
  totalPrice: PriceType;
  discount?: number;
  kmIncluded?: number;
  pricePerKm: PriceType;
  ratingsCount: number;
  averageRating: number;
  tags?: VehicleTag[];
  favorite?: boolean;
  hideFavorite?: boolean;

  onFavoriteClick?(event: React.MouseEvent<HTMLDivElement>): void | boolean;
}

function CarCard({ noOfDays = 1, ...props }: Props) {
  const lang = useLanguage();
  const isMoreDays = noOfDays > 1;

  const Discount = () =>
    props.showPricePerOneDay && typeof props.discount === 'number' && props.discount !== 0 ? (
      <Label className={styles.discount}>
        <NumberFormat fractions={[0, 1]} negative suffix="%" value={props.discount} />
      </Label>
    ) : null;

  const Favorite = () =>
    !props.hideFavorite ? (
      <HeartIcon className={cn(styles.heartIcon, { active: props.favorite })} onClick={props.onFavoriteClick} />
    ) : null;

  return (
    <div className={props.className}>
      <div className={styles.image}>
        <Image alt="car" loading="lazy" size="md" src={props.image} />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>
          <h4 className="small">{props.name}</h4>
          <Favorite />
        </div>

        <div className={styles.contentRow}>
          <div className={styles.contentLeft}>
            <div className={styles.bulletPoints}>
              {typeof props.kmIncluded === 'number' ? (
                <T className={styles.bulletPoint} data={{ count: props.kmIncluded }} id="carCard.kmIncluded" />
              ) : null}
              <T
                className={styles.bulletPoint}
                data={{ price: formatPrice(props.pricePerKm, lang) }}
                id="carCard.pricePerKm"
              />
            </div>
            <div
              className={cn(styles.distance, {
                hide: typeof props.distance !== 'number',
              })}
            >
              {typeof props.distance === 'number' ? (
                <>
                  <i className="icon icon-pin" />
                  <T
                    data={{
                      distance: formatNumber(props.distance, lang, [0, 2]),
                    }}
                    id="carCard.distance"
                  />
                </>
              ) : null}
            </div>
            <div
              className={cn(styles.rating, {
                'no-review': !props.ratingsCount,
              })}
            >
              <i className="icon icon-star" />
              <b>
                {props.ratingsCount ? (
                  <>
                    <NumberFormat fractions={2} value={props.averageRating} />
                    <T className={styles.ratingsCount} data={{ count: props.ratingsCount }} id="carCard.reviewsCount" />
                  </>
                ) : (
                  <T id="carCard.reviews_new" />
                )}
              </b>
            </div>
          </div>

          <div className={styles.contentRight}>
            <Discount />
            <div className={styles.prices}>
              <T as="span" id="carCard.pricePerDay" />
              <div className={styles.pricePerDayLine}>
                {props.showPricePerOneDay ? (
                  <Price className={styles.pricePerOneDay} price={props.pricePerOneDay} />
                ) : null}
                <Price className={styles.pricePerDay} price={props.pricePerDay} />
              </div>
              {isMoreDays && props.totalPrice ? (
                <T
                  className={styles.totalPrice}
                  data={{ price: formatPrice(props.totalPrice, lang) }}
                  id="carCard.totalPrice"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tags}>
        <Discount />
        {props.tags?.map(tag => (
          <div className={styles.tag} key={tag.id}>
            {tag.name}
          </div>
        ))}
      </div>
      <div className={styles.heartIconWrapper}>
        <Favorite />
      </div>
    </div>
  );
}

export function CarCardLarge(props: Props) {
  return (
    <TContext name="large">
      <CarCard {...props} className={cn(styles.containerLarge, props.className)} />
    </TContext>
  );
}

export default function CarCardSmall(props: Props) {
  return <CarCard {...props} className={cn(styles.containerSmall, props.className)} />;
}

interface SkeletonProps {
  className?: string;
}

export function CarCardSkeleton({ className }: SkeletonProps) {
  return (
    <div className={cn(styles.skeleton, className)}>
      <div className={styles.image} />
      <div className={styles.content} />
    </div>
  );
}

export function CarCardSkeletonLarge({ className }: SkeletonProps) {
  return <CarCardSkeleton className={cn(styles.skeletonLarge, className)} />;
}
